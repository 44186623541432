@if ((eventThrottleRate$ | async); as eventThrottleRate) {
  @if (eventThrottleRate > 0 && bannerVisible) {
  <mat-card>
    <mat-card-header>
      <mat-card-title> Missing some errors? </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      You've reached the maximum number of events that your plan allows for this
      billing period. You can
      <a [routerLink]="[activeOrgSlug$ | async, 'settings', 'subscription']">
        change your subscription</a>
      if you'd like to get things moving again.
      <button mat-icon-button (click)="hideBanner()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>
  }
}
